import { useState, Fragment } from 'react';
import style from './excelAdIn.module.scss';
import { useDispatch } from 'react-redux';
import { excelAction } from '../../store/excel-slice';
import { link } from '../../link';

const ExcelAdIn = () => {
  const dispatch = useDispatch();

  const [validate, setValidate] = useState(false);
  const [isExcel, setIsExcel] = useState(false);
  const [excelFile, setExcelFile] = useState(undefined);

  const onSubmit = () => {
    if (excelFile !== undefined) {
      setIsExcel(true);
    }
  };

  const excelReader = async () => {
    const allowedExtensions = ['.xlsx', '.xls'];
    let rendomId = Math.floor(Math.random() * 2001);
    let id = rendomId
    sessionStorage.setItem("id", id);
    if (excelFile && allowedExtensions.includes(excelFile.name.slice(-5))) {
      dispatch(excelAction.excelUpLoad(true));
      let id  = sessionStorage.getItem("id")
      const formData = new FormData();
      formData.append('file', excelFile);
      
      try {
        const response = await fetch( link + '/excel?id=' + id, {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log('Success:', data);
          // Faceți ceva cu datele de răspuns, dacă este necesar
        } else {
          throw new Error('Server response is not in JSON format');
        }
      } catch (error) {
        console.error('Error:', error);
        // Gestionați erorile aici
      }
    } else {
      console.log('Did not pass');
    }
  };
    
  const start = (
    <div className={style.container}>
      <button className={style.start} onClick={excelReader}>
        Start
      </button>
    </div>
  );

  const addExcel = (
    <div className={style.container}>
      {!validate && (
        <div className={style.addExcel}>
          <label htmlFor="file-input" className={style.fileLabel}>
            Add Excel
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            className={style.fileInput}
            onChange={(e) => {
              setExcelFile(e.target.files[0]);
              setValidate(true);
            }}
          />
        </div>
      )}
      {validate && (
        <button className={style.start} onClick={onSubmit}>
          Validate
        </button>
      )}
    </div>
  );

  return <Fragment>{isExcel ? start : addExcel}</Fragment>;
};

export default ExcelAdIn;



