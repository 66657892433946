import { useDispatch } from 'react-redux';
import { excelAction } from '../store/excel-slice';
import { link } from '../link';

const useQAfetch = () => {
  const dispatch = useDispatch();

  const fetchServer = async (string) => {
    const url = link;
    const secretKey =  encodeURIComponent('Gab10iel9')
    let id  = sessionStorage.getItem("id")
    let str = string;
    let responseCode;
    let dataRespons;
    let fillNan = [];
    try {
      const headers = new Headers();
      headers.append('name', `Bearer ${id}`);
      let response;
      if (str === 'CountNullValue') {
        response = await fetch(url + '/' + str + '/' + secretKey + "?id=" + id);
      } else if (str === 'ExcelSummary') {
        response = await fetch(url + '/' + str + '/' + secretKey + "?id=" + id);
      } else if (str === 'ExtractValue') {
        response = await fetch(url + '/' + str + '/' + secretKey + "?id=" + id);
      } else if (str === 'Chart') {
        response = await fetch(url + '/' + str + '/' +secretKey + "?id=" + id);
      } else if (str === 'UnderCostruction') {
        dataRespons = {
          res: 'Under costruction',
          question: str,
        };
        console.log(dataRespons);
        dispatch(excelAction.excelResponse(dataRespons));
        return;
      } else if (str === 'fillNANW0' ) {
        response = await fetch(url + '/' + str + '/' + secretKey + "?id=" + id);
      } else {
        response = await fetch(url + '/' + str + '/' + secretKey + "?id=" + id);
      }

      responseCode = response.status;

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (str === 'CountNullValue') {
        dataRespons = { 
          sessionID: data.sessionID,
          res: data.value, 
          question: str };
      } else if (str === 'ExcelSummary') {
        dataRespons = {
          sessionID: data.sessionID,
          rows: data.rows,
          columns: data.columns,
          col_dtypes: data.col_dtypes,
          max_value: data.max_value,
          min_value: data.min_value,
          question: str,
        };
      } else if (str === 'ExtractValue') {
        dataRespons = {
          sessionID: data.sessionID,
          value: data.value,
          columns: data.columns,
          row: data.row,
          question: str,
        };
      } else if (str === 'Chart') {
        dataRespons = { res: data.value, question: str, sessionID: data.sessionID};
      } else if ('fillNANW0') {
        dataRespons = data;
        for (const key in dataRespons) {

          fillNan.push({
            id: dataRespons[key].column,
            question: str,
            column: dataRespons[key].column,
            value: dataRespons[key].arr,
          });
        }
      }  else {
        dataRespons = {
          sessionID: data.sessionID,
          res: data.value,
          question: str,
        };
      }

      if (str === 'fillNANW0') {
        dispatch(excelAction.excelResponse(fillNan));
      } else {
        dispatch(excelAction.excelResponse(dataRespons));
      }
    } catch (error) {
      console.log(error, responseCode);
      dataRespons = {
        res: `We tryed to call the server but the server code is ${responseCode}`,
        question: 'Error',
      };
      dispatch(excelAction.excelResponse(dataRespons));
    }
  };

  return { fetchServer };
};

export default useQAfetch;
