import style from './question.module.scss';
import Button from './AskQuestion';

const Question = () => {
  const question = [
    {
      id: 'bt1',
      question: 'CountNullValue',
      text: 'Count null value' ,
      para: '0',
    },
    {
      id: 'bt2',
      question: 'ExtractValue',
      text: 'Extract Value',
      para: '1',
    },
    {
      id: 'bt3',
      question: 'ExcelSummary',
      text: 'Excel Summary',
      para: '2',
    },
    {
      id: 'bt4',
      question: 'Chart',
      text: 'Chart',
      para: '3',
    },
    {
      id: 'bt5',
      question: 'fillNANW0',
      text: 'fill NAN W 0',
      para: '4',
    },
    {
      id: 'bt6',
      question: 'UnderCostruction',
      text: 'Under costruction',
      para: '5',
    },
  ];

  const btnMap = question.map((el) => (
    <Button key={el.id} id={el.para} question={el.question} text={el.text}/>
  ));

  return <div className={style.qaBtnContainer}>{btnMap}</div>;
};

export default Question;
